<template>
    <div class="container">
        <div class="search">
            <el-button
                type="primary"
                class="btnClass"
                size="small"
                @click="dialogFormVisible = true"
                >批量生成优惠券</el-button
            >
        </div>
        <!-- 列表 -->
        <el-table :data="list" size="mini">
            <el-table-column label="优惠场景" width="120">
                <template v-slot="scope">
                    <span>{{ scope.row.promotion }}</span>
                </template>
            </el-table-column>
            <el-table-column label="优惠券码" width="220">
                <template v-slot="scope">
                    <span>{{ scope.row.coupon_code }}</span>
                </template>
            </el-table-column>
            <el-table-column label="试用时长" width="90">
                <template v-slot="scope">
                    <span>{{ scope.row.time }} {{ scope.row.time_unit }}</span>
                </template>
            </el-table-column>
            <el-table-column label="关联用户">
                <template v-slot="scope">
                    <span>{{ scope.row.uid }}</span>
                </template>
            </el-table-column>
            <el-table-column label="关联设备">
                <template v-slot="scope">
                    <span>{{ scope.row.uuid }}</span>
                </template>
            </el-table-column>
            <el-table-column label="状态" width="80">
                <template v-slot="scope">
                    <span v-if="scope.row.status == 1"
                        ><el-tag type="success">有效</el-tag></span
                    >
                    <span v-if="scope.row.status == 2"
                        ><el-tag type="info">已使用</el-tag></span
                    >
                    <span v-if="scope.row.status == 3"
                        ><el-tag type="success">被占用</el-tag></span
                    >
                    <span v-if="scope.row.status == 4"
                        ><el-tag type="warning">无效</el-tag></span
                    >
                </template>
            </el-table-column>
            <el-table-column
                label="过期时间"
                prop="expired_time"
                :formatter="
                    (row) =>
                        row.expired_time && util.dateFormat(row.expired_time)
                "
            >
            </el-table-column>
            <el-table-column
                label="创建时间"
                prop="create_time"
                :formatter="
                    (row) => row.create_time && util.dateFormat(row.create_time)
                "
            >
            </el-table-column>
            <el-table-column
                label="使用时间"
                prop="usage_time"
                :formatter="
                    (row) => row.usage_time && util.dateFormat(row.usage_time)
                "
            >
            </el-table-column>
            <el-table-column
                label="修改时间"
                prop="update_time"
                :formatter="
                    (row) => row.update_time && util.dateFormat(row.update_time)
                "
            >
            </el-table-column>
        </el-table>
        <el-pagination
            @current-change="handleCurrentChange"
            v-model:currentPage="page"
            :page-size="rows"
            layout="total, prev, pager, next"
            :total="total"
            background
        >
        </el-pagination>

        <!-- 生成优惠券 -->
        <el-dialog title="生成优惠券" v-model="dialogFormVisible" width="40%">
            <el-form ref="form" :model="form" label-width="110px" size="small">
                <el-form-item label="数量&nbsp;" required>
                    <el-input
                        v-model="form.number"
                        type="number"
                        autocomplete="off"
                        style="width: 220px"
                        @blur="form.number = Number(form.number)"
                    ></el-input>
                </el-form-item>
                <el-form-item label="试用时长&nbsp;" required>
                    <el-input
                        v-model="form.time"
                        type="number"
                        autocomplete="off"
                        style="width: 220px"
                        @blur="form.time = Number(form.time)"
                    ></el-input>
                    <el-select
                        v-model="form.time_uint"
                        placeholder="请选择试用周期"
                        style="width: 220px"
                    >
                        <el-option label="天" value="DAY"></el-option>
                        <el-option label="周" value="WEEK"></el-option>
                        <el-option label="月" value="MONTH"></el-option>
                        <el-option label="年" value="YEAR"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="过期时间&nbsp;" required>
                    <el-date-picker
                        v-model="form.end_time"
                        type="datetime"
                        placeholder="选择日期时间"
                        value-format="timestamp"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogFormVisible = false" size="small"
                        >取 消</el-button
                    >
                    <el-button
                        type="primary"
                        class="btnClass"
                        size="small"
                        @click="batchCreate"
                        >生成</el-button
                    >
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6">
import api from "../../../axios/pack";
import util from "../../../util/util";
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      util,
      times: {
        DAY: "天",
        WEEK: "周",
        MONTH: "月",
        YEAR: "年",
      },
      statusList: {
        1: "有效",
        2: "已使用",
        3: "被占用",
      },
      page_show: true,
      coupons: {},
      page: 1,
      rows: 12,
      total_page: 1,
      dialogFormVisible: false,
      form: {
        end_time: 0,
        number: 0,
        promotion: "CLOUD_AI",
        time_uint: "",
        time: 0,
      },
      list: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.Search();
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      sessionStorage.setItem("currentPage", val);
      this.Search();
    },
    Search() {
      api.CouponList({ page: this.page, rows: this.rows }).then((res) => {
        if (res.data.code == 200) {
          this.list = res.data.data.data;
          this.total = res.data.data.page_info.total;
          return;
        }
        this.$message.error("获取失败" + res.data.msg);
      });
    },
    batchCreate() {
      if (this.form.number === 0) {
        this.$message.error("数量不能为空");
        return;
      }
      if (this.form.time === 0 || this.form.time_uint == "") {
        this.$message.error("试用时长不能为空");
        return;
      }
      if (this.form.end_time == 0 || this.form.end_time == null) {
        this.$message.error("过期时间不能为空");
        return;
      }
      api
        .CouponCreate({
          ...this.form,
          end_time: Math.floor(this.form.end_time / 1000),
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "success",
              type: "success",
            });
            this.Search();
            this.dialogFormVisible = false;
          } else {
            this.$message.error("error:" + res.data.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.container {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  .search {
    text-align: right;
    padding-top: 8px;
    margin-bottom: 8px;
  }

  .el-form {
    ::v-deep(.el-form-item__label) {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      opacity: 0.8;
      padding-right: 0px;
    }

    .el-form-item {
      margin-left: 24px;
    }
  }

  .btnClass {
    color: #FFF;
    background-color: #FB6D07;
    border-color: #FB6D07;
  }

  .textBtn {
    color: #FB6D07;
  }

  ::v-deep(thead) {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #000;
    opacity: 0.8;

    th {
      font-weight: 400;
      background-color: #F0F2F5;
    }
  }

  ::v-deep(tbody) {
    tr {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      opacity: 0.8;
    }
  }
}
</style>
